import './App.css';
import Navbar from './Navbar'
import {AiFillLinkedin, AiFillGithub, AiFillInstagram, AiFillMail, AiFillEnvironment, AiFillPhone} from 'react-icons/ai'
import axios from 'axios';
import React from 'react';

const url = 'https://dev-backend.ketanbijwe.com/api/v1/kb/add-enquiry';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }
  handleSubmit(e){
    e.preventDefault();
    axios.request({
      method: 'POST',
      url: url,
      headers: {
        'content-type': "application/json"
      },
      data: this.state
    // axios({
    //   method: "POST",
    //   url: 'https://dev-backend.ketanbijwe.com/api/v1/kb/add-enquiry',
    //   data: this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message Sent.");
        this.resetForm()
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }
  resetForm(){
    this.setState({name: '', email: '', message: ''})
  }
  render() {
    return(

      <main className='px-8 md:px-20 lg:px-40'>
    
      <Navbar />
  
      <section id='home' className='min-h-screen text-center mt-4 flex flex-col justify-center '>
          <div className="mx-auto bg-gradient-to-b text-purple-700 rounded-full w-56 h-56 relative overflow-hidden md:h-96 md:w-96 mb-2">
            <img src='/avatar.png' layout="fill" objectFit="cover" />
          </div>
          <h1 className='text-3xl md:text-4xl font-medium text-purple-700'>Ketan Bijwe</h1>
          <h3 className='text-xl md:text-2xl'>Senior SRE | Cloud Systems Engineer</h3>
          <p className='text-md py-1 text-gray-900 md:text-xl'>
            Passionate and enthusiastic individual, self-learning new technologies. Focused on Cloud computing, Devops and Web development. 
          </p>
          <div className='text-5xl py-4 flex justify-center gap-16 text-gray-900'>
            <a href="https://www.linkedin.com/in/ketanbijwe/">
              <AiFillLinkedin />
            </a>
            <a href="https://github.com/ketanbijwe">
              <AiFillGithub />
            </a>
            {/* <AiFillInstagram /> */}
          </div>
      </section>
  
      <section id='aboutme' className=''>
        <h1 className='text-xl font-medium'>About me</h1>
        <p className='mt-1 text-md'>
          • A result-oriented individual who has started journey in Devops in Apr 2021 prior to enriched SDET experience of 6 years.
        </p>
        <p className='mt-1 text-md'>
          • Certified AWS Solutions Architect Associate.
        </p>
        <p className='mt-1 text-md'>
          • Hands on experience of developing CICD pipeline, AWS, Datadog, Terraform, Docker & Selenium.
        </p>
        <p className='mt-1 text-md'>
          • Good experience with Python & Java.
        </p>
        <p className='mt-1 text-md'>
          • Exceptional communication, collaboration & team building skills with proficiency in grasping new technical concepts quickly and utilising the same in a productive manner.
        </p>
  
        {/* <div className='mt-4 flex flex-col md:flex-row gap-8'>
          <div className='w-full shadow-lg rounded-md px-4 py-5 flex flex-col items-center text-center gap-1'>
            <img src='software.png' className='w-48' alt='gambar' />
            <h2 className='text-xl'>Software Development</h2>
            <p className='text-gray-500'>I can help you solve a problem, build a product or grow existing project.</p>
            <p className='text-xl '>Tools I Use</p>
            <ul>
              <li>React</li>
              <li>Node JS (Express)</li>
              <li>Python (Flask)</li>
            </ul>
          </div>
  
          <div className='w-full shadow-lg rounded-md px-4 py-5 flex flex-col items-center text-center gap-1'>
            <img src='cloud.png' className='w-48' alt='gambar' />
            <h2 className='text-xl'>Cloud & IT Solutions Architect</h2>
            <p className='text-gray-500'>I can help you solve a problem, build a product or grow existing project.</p>
            <p className='text-xl'>Tools I Use</p>
            <ul>
              <li>AWS, Azure, Digital Ocean</li>
              <li>Openstack</li>
              <li>Kubernetes & Docker</li>
            </ul>
          </div>
  
          <div className='w-full shadow-lg rounded-md px-4 py-5 flex flex-col items-center text-center gap-1'>
            <img src='ops.png' className='w-48' alt='gambar' />
            <h2 className='text-xl'>DevOps Architect</h2>
            <p className='text-gray-500'>I can help you solve a problem, build a product or grow existing project.</p>
            <p className='text-xl '>Tools I Use</p>
            <ul>
              <li>Gitlab</li>
              <li>Ansible</li>
              <li></li>
            </ul>
          </div>
  
        </div> */}
  
      </section>
  
      <section id='skills' className='mt-10'>
        <h1 className='text-xl font-medium'>Skills</h1>
        <p className='mt-1 text-md'>
          AWS, Terraform, Datadog, Python, CI/CD, Azure DevOps, Java, JS
        </p>
      </section>
  
      {/* <section id='portfolio' className='mt-10'>
        <h1 className='text-xl font-medium'>Portfolio</h1>
        <p className='mt-1 text-md'>Here are some projects that I have managed.</p>
      </section> */}
  
      <section id='license' className='mt-10'>
        <h1 className='text-xl font-medium'>License & Certifications</h1>
        <p className='mt-1 text-md'>Here are some of the certifications I have.</p>
        
        <div className='flex flex-col md:flex-row gap-8 mt-4'>
          <div className='w-full md:w-2/6 shadow-md rounded '>
            <div className='h-48 md:h-56 rounded-t-md relative overflow-hidden'>
              <img src='./terraform.png' layout="fill" objectFit="cover"/>
            </div>
            <div className='p-4'>
              <h1 className='text-xl font-medium'>Terraform Associate</h1>
              {/* <p className=''>Dicoding Indonesia</p> */}
              <p className='text-sm text-gray-500'>July 2022</p>
            </div>
          </div>
  
          <div className='w-full md:w-2/6 shadow-md rounded '>
            <div className='h-48 md:h-56 rounded-t-md relative overflow-hidden'>
              <img src='./aws.png' layout="fill" objectFit="cover"/>
            </div>
            <div className='p-4'>
              <h1 className='text-xl font-medium'>AWS Solutions Acrhitect - Associate</h1>
              {/* <p className=''>Dicoding Indonesia</p> */}
              <p className='text-sm text-gray-500'>Aug 2021</p>
            </div>
          </div>
  
          <div className='w-full md:w-2/6 shadow-md rounded '>
            <div className='h-48 md:h-56 rounded-t-md relative overflow-hidden'>
              <img src='./oraclecloud.png' layout="fill" objectFit="cover"/>
            </div>
            <div className='p-4'>
              <h1 className='text-xl font-medium'>OCI Foundation - Associate</h1>
              {/* <p className=''>Dicoding Indonesia</p> */}
              <p className='text-sm text-gray-500'>May 2020</p>
            </div>
          </div>
        </div>
  
      </section>
      
      {/* <section id='honor' className='mt-10'>
      <h1 className='text-xl font-medium'>Honors & Rewards</h1>
      <p className='mt-1 text-md'>Here are some of the honor and rewards I have.</p>
  
      <div className='flex rounded shadow-md flex-col md:flex-row px-2 py-4'>
        <div className='pl-4 mt-2 md:w-3/6'>
          <p className="font-bold">Top 10 SELEKNAS Asean Skill Competition 2022</p>
          <p className='text-gray-500'>Issued by KEMENAKER RI</p>
          <ul className='list-disc pl-4'>
            <li>Linux Server</li>
            <li>Windows Server</li>
            <li>Linux Routing</li>
            <li>Cisco Troubleshooting</li>
            <li>Cisco Network Analysis</li>
            <li>Network and System Programmabillity</li>
            <li>Containerized Service</li>
          </ul>
        </div>
        <div className='pl-4 mt-2 md:w-3/6'>
          <p className="font-bold">3rd Place Winner of IT Network System Administration - LKS Jawa Tengah 2022</p>
          <p className='text-gray-500'>Issued by KEMENDIKBUD RI</p>
          <ul className='list-disc pl-4'>
            <li>Linux Server</li>
            <li>Windows Server</li>
            <li>Cisco Configuration</li>
            <li>Cisco Troubleshooting</li>
            <li>Network and System Programmabillity</li>
            <li>Containerized Service</li>
          </ul>
        </div>
        <div className='pl-4 mt-2 md:w-3/6'>
          <p className="font-bold">1st Place winner of IT Network System Administration - LKS Kab. Tegal 2022</p>
          <p className='text-gray-500'>Issued by KEMENDIKBUD RI</p>
          <ul className='list-disc px-4'>
            <li>Linux Server</li>
            <li>Cisco Configuration</li>
          </ul>
        </div>
      </div>
      
      </section> */}
  
      <section id="footer" className='mt-5 rounded bg-black py-8 px-8'>
        <div className='text-white md:text-center'>
          <h1 className='font-medium text-lg'>Get in touch</h1>
          {/* <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
            <ul className='md:flex justify-center gap-4'>
              <li className='flex items-center gap-2'>
                <div className="form-group">
                  <label htmlFor="name">Name : </label>
                  <input type="text" className="form-control text-center text-gray-900 rounded-md" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                </div>
              </li>
              <li className='flex items-center gap-2'>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email-address : </label>
                  <input type="email" className="form-control text-center text-gray-900 rounded-md" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                </div>
              </li>
              <li className='flex items-center gap-2'>
                <div className="form-group">
                  <label htmlFor="message">Message : </label>
                  <textarea className="form-control text-center text-gray-900 rounded-md" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
                </div>
              </li>
              <button type="submit" className="btn btn-primary">Submit</button>
            </ul>
          </form> */}

          {/* <ul className='md:flex justify-center gap-4'>
            <li className='flex items-center gap-2'>
              <div className='mt-1 text-md'>
                <label>Name : </label>
                <input className='form-control text-center text-gray-900 rounded-md' maxlength='32' type="text" />
              </div>
            </li>
            <li className='flex items-center gap-2'>
              <div className='mt-1 text-md'>
                <label>Email : </label>
                <input className='form-control text-center text-gray-900 rounded-md'  maxlength='32' type="email" />
              </div>
            </li>
            <li className='flex items-center gap-2'>
              <div className='mt-1 text-md'>
                <label>Message : </label>
                <input className='form-control text-center text-gray-900 rounded-md'  maxlength='100' type="message" />
              </div>
            </li>
            <button className='btn btn-primary' type="submit">Submit</button>
          </ul> */}
  
          {/* <hr className="my-5 border-gray-100 sm:mx-auto dark:border-gray-700 lg:my-5"/>
          <h1 className='font-medium text-lg'>Alternatively</h1> */}
          <ul className='md:flex justify-center gap-4'>
            <li className='flex items-center gap-2'>
              <AiFillMail />
              <a href = "mailto:hello@ketanbijwe.com?subject = Hello&body = Message" >
                <p>hello@ketanbijwe.com</p>
              </a>
              <a href="https://www.linkedin.com/in/ketanbijwe/">
              <AiFillLinkedin />
              </a>
              <a href="https://github.com/ketanbijwe">
                <AiFillGithub />
              </a>
            </li>
          </ul>
  
          <ul className='md:flex justify-center gap-4'>
            <li className='flex items-center gap-2'>
              <AiFillEnvironment />
              <a href = "https://www.google.com/maps/place/London">
                <p>London, UK</p>
              </a>
            </li>
          </ul>
  
          <hr className="my-5 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-5"/>
  
          <p className="text-center text-sm text-gray-300 sm:text-center">
            © 2023
            <a href="#"> Ketan Bijwe</a>. All Rights Reserved.
          </p>
  
        </div>
        
      </section>
  
      </main>

      // <div className="App">
        // <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
        //   <div className="form-group">
        //       <label htmlFor="name">Name</label>
        //       <input type="text" className="form-control" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
        //   </div>
        //   <div className="form-group">
        //       <label htmlFor="exampleInputEmail1">Email address</label>
        //       <input type="email" className="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
        //   </div>
        //   <div className="form-group">
        //       <label htmlFor="message">Message</label>
        //       <textarea className="form-control" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
        //   </div>
        //   <button type="submit" className="btn btn-primary">Submit</button>
        // </form>
      // </div>
    );
  }
  onNameChange(event) {
    this.setState({name: event.target.value})
  }
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }
  onMessageChange(event) {
    this.setState({message: event.target.value})
  }
}

export default App;
